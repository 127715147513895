import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import {UserProfile } from"../ApiContext";
import Loader from "../Utils/Loader";
function Login(props) {
  const [input, setInput] = useState({ mobileno: "" });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const { updateUserProfile } = UserProfile();
  const commonChange = (event) => {
    var pattern = new RegExp(/^[0-9\b]+$/);
    const { name, value } = event.target;
    if (name === "mobileno") {
      if (value === "" ||pattern.test(value)) {
        setInput((prevInput) => ({ ...prevInput, [name]: value }));
      }
    } else {
      setInput((prevInput) => ({ ...prevInput, [name]: value }));
    }
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["mobileno"]) {
      isValid = false;
      errors["mobileno"] = "Mobile is required.";
    }
    if (typeof input["mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["mobileno"])) {
        isValid = false;
        errors["mobileno"] = "Please enter only number.";
      } else if (input["mobileno"].length !== 10) {
        isValid = false;
        errors["mobileno"] = "Enter valid mobile number.";
      }
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Password is required.";
    }

    setErrors(errors);

    return isValid;
  };
  function ShowHidePassword() {
    var pwdtxt = document.getElementById("passwordtxt");
    var pwdicon = document.getElementById("passwordicon");
    if (pwdtxt.type === "password") {
      pwdtxt.type = "text";
      pwdicon.textContent = "visibility_off";
    } else {
      pwdtxt.type = "password";
      pwdicon.textContent = "visibility";
    }
  }
  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      var details =
        "UserName=" +
        encodeURIComponent(input.mobileno) +
        "&Password=" +
        encodeURIComponent(input.password) +
        "&grant_type=password&Scope=Dealerlogin";
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",

        },
        body: details,
      };
      fetch(process.env.REACT_APP_API_ENDPOINT + "/login", requestOptions)
        .then((response) => response.json())
        .then(async (res) => {
          setLoading(false)
          if (res.access_token === undefined) {
            toast.error(res.error_description);
          } else {
            toast.success("Login successfully");
            localStorage.setItem("ca1rt6_t", res.access_token);
            const header = {
              headers: {
                Authorization: "Bearer " + res.access_token,
              },
            };
           await fetch(
              process.env.REACT_APP_API_ENDPOINT +"/api/BrandingProfile/All",header)
              .then((response) => response.json())
              .then((res) => {
                if (res.Success===true) {
                  // updateUserProfile(res.Data[0])
                  localStorage.setItem("Ca1itd", res.Data[0].DealerRand);
                  localStorage.setItem("Ca1nioamed", res.Data[0].Firmname);
                }
                window.userid = res.userName;
                window.close();
                navigate("/");
              });
           
            window.userid = res.userName;
            window.close();
            navigate("/");
          }
        });
    } 
  };

  return (
    <div style={{marginTop:"-66px"}}>
      {loading?<Loader/>:null}
      <div className="login">
        <div className="login-div">
          <div className="text-center pt-3 logo-div">
            <img src="assets/images/logo.png" className="logo-img" alt="" />
            <img
              src="assets/images/logo.png"
              className="logo-img logo-imgabs"
              alt=""
            />
          </div>
          <div className="login-bg">
            <h2>Login</h2>

            <div className="inputwithicon">
              <input
                type="text"
                placeholder="Mobile No."
                name="mobileno"
                value={input.mobileno}
                onChange={commonChange}
              />
              <span className="material-symbols-outlined">phone_iphone</span>
              <label className="text-danger ms-3">{errors.mobileno}</label>
            </div>

            <div className="inputwithicon">
              <input
              id ="passwordtxt"
                type="password"
                placeholder="Password"
                name="password"
                onChange={commonChange}
                autoComplete="off"
              />
              <span className="material-symbols-outlined">lock</span>
              <div className="material-symbols-outlined" id="passwordicon" 
              onClick={()=>ShowHidePassword()}>visibility</div>
              <label className="text-danger ms-3">{errors.password}</label>
              <Link to="/ForgetPassword" className="fgtpwd">
                Forgot Password?
              </Link>
            </div>
            <div className="pt-4">
              <input
                type="submit"
                className="login-btn"
                value="Login"
                onClick={(e) => handleSubmit()}
              />
            </div>
            <div className="new-account">
              Don't have an account? <Link to="/Register">Create Now...</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
