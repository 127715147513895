import React, { useState, useEffect } from "react";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { Toast, Userlogin, customStyles } from "../Utils/general-function";
import Loader from "../Utils/Loader";
import axios from "axios";
import Select from "react-select";

function Addlead(props) {
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [Saleslist, setSaleslist] = useState([]);
  const [SelectedModels, setSelectedModels] = useState([]);
  const [moedlist, setModelist] = useState([]);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const isCarNumber = e.target.name === "carno";
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: isCarNumber ? value.toUpperCase() : value,
    }));
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["name"]) {
      isValid = false;
      errors["name"] = "This feild is required";
    }
    // if (typeof input["name"] !== "undefined") {
    //   var re=new RegExp(/^[a-zA-Z ]*$/)
    //   if (!re.test(input["name"])) {
    //     isValid = false;
    //     errors["name"] = "Please enter only characters.";
    //   }
    // }
    if (!input["Mobileno"]) {
      isValid = false;
      errors["Mobileno"] = "This feild is required";
    }
    if (typeof input["Mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["Mobileno"])) {
        isValid = false;
        errors["Mobileno"] = "Please enter only number.";
      } else if (input["Mobileno"].length !== 10) {
        isValid = false;
        errors["Mobileno"] = "Enter valid mobile number.";
      }
    }
    if (SelectedModels.length === 0) {
      isValid = false;
      errors["models"] = "This feild is required";
    }

    setErrors(errors);

    return isValid;
  };
  const handleSubmit = () => {
    if (Validate()) {
      setLoading(true);
      var param = {
        Name: input.name,
        Contactno: input.Mobileno,
        Carno: "",
        nDealerRandno: localStorage.getItem("Ca1itd"),
        nRemarks: input.remark ? input.remark : "",
        salesexecutiveId: input.salesman ? input.salesman : "0",
        LeadType: "MenualLead",
        Modelid: SelectedModels.map((item) => item.Value).join(",")
      };

      const formBody = new URLSearchParams(param).toString();
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      };

      fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/LeadQRCode/AddLeadQRCode",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          if (res.Success) {
            navigate("/Leaddata");
          }
          Toast(res.Success, res.Message, res.Message);
        });
    }
  };
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/login")
      return;
    }

    setLoading(true);
    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/SalesExecutive/GetSalesExecutiveDetails?dealerrandno=" + localStorage.getItem("Ca1itd"))
      .then((res) => {
        setSaleslist(res.data.Data || []);
        setLoading(false)
      })
      .catch((error) => console.log(error)
      )
    fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Allactivemodel")
      .then((response) => response.json())
      .then((res) => {
        setModelist(res.Data);
        setLoading(false);
      });

  }, [])

  const ondropdown = (opt) => {
    setSelectedModels(opt);
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div
        className="content m-4 px-4 pb-4"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "10px",
        }}
      >
        <div className="text-center">
          <h2 className="page-title">Add Lead</h2>
        </div>
        <div className="form-div">
          <label className="form-label">
            Sales executive
          </label>
          <select
            className="form-select form-control"
            name="salesman"
            value={input.salesman}
            onChange={handleChange}
          >
            <option value="" disable="disable">
              Select...
            </option>
            {Saleslist.map((list) => (
              <option value={list.id} key={list.id}>
                {list.Name}
              </option>
            ))}
          </select>
          {/* <span className="text-danger">{errors.salesman}</span> */}
        </div>
        <div className="form-div">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            // value={input.name}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.name}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Mobile No</label>
          <input
            type="text"
            className="form-control"
            name="Mobileno"
            // value={input.Mobileno}
            onChange={(e) => {
              setInput((prevInput) => ({
                ...prevInput,
                Mobileno: e.target.value.startsWith("+91")
                  ? e.target.value.replace("+91", "")
                  : e.target.value,
              }));
            }}
          />
          <span className="text-danger">{errors.Mobileno}</span>
        </div>

        <div className="form-div">
          <label className="form-label">
            Car model
          </label>
          <Select
            isMulti
            name="models"
            options={moedlist}
            getOptionLabel={(option) => option.Text}
            getOptionValue={(option) => option.Value}
            onChange={ondropdown}
            closeMenuOnSelect={false}
            blurInputOnSelect={false}
            isSearchable={true}
            styles={customStyles}
          ></Select>
          <span className="text-danger">{errors.models}</span>
        </div>
        {/* <div className="form-div">
          <label className="form-label">Car No. <span
              style={{
                fontSize: "13px",
                color: "#686868",
                letterSpacing: "0.5px",
               }}>
              (Ex. GJ01HQ9999)
            </span></label>
          <input
            type="text"
            className="form-control"
            name="carno"
            value={input.carno} 
            onChange={handleChange}
          />
          <span className="text-danger">{errors.carno}</span>
        </div> */}
        <div className="form-div">
          <label className="form-label">Customer requirement</label>
          <input
            type="text"
            className="form-control"
            name="remark"
            // value={input.remark}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.remark}</span>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Addlead;
