import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { Toast, fetchDataFromApi } from './Utils/general-function';
import CatimgUpload from "./Uploadimage/Cardoc_Upload";
import jsPDF from "jspdf";
import Loader from './Utils/Loader';
import Document from './Document';
function AddDoc({ Catloguedetail, open, handlemodal }) {

  const [imageData, setImageData] = useState([]);
  const [DOclist, setDOclist] = useState([]); //for getting all multiple types of doc
  const [Doctype, setDoctype] = useState(); // for storing selected doc type
  const [loading, setLoading] = useState(true);
  const [Docname, setDocname] = useState("");
  const [error, setError] = useState({});
  const [CarDOclist, setCarDOclist] = useState([]);
  const handleImageChange = (e) => {
    setDocname(e.target.value);
  };
  const Onchange = (e) => {
    setDoctype(e.target.value);
  };
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (imageData.length === 0) {
      isValid = false;
      errors["doc"] = "Please upload document";
    }
    // if (Docname === "") {
    //   isValid = false;
    //   errors["name"] = "Kindly name the document for saving.";
    // }

    setError(errors);

    return isValid;
  };
  const handleGeneratePDF = () => {
    if (validate()) {
      setError({});
      setLoading(true);
      const param = {
        carid: Catloguedetail.carid,
        Carno: Catloguedetail.carnumber,
        documentid: Doctype,
        documentlist: imageData[0],
        documentname: Docname,
      };
      var formBody = [];
      for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "/api/cardocumentmaster/Addcardocument",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.Success) {
            setImageData([]);
            setDoctype(DOclist[0].id);
            fetchDocuments();
            // setShow(false);
            setLoading(false);
          } else {
            setLoading(false);
            Toast(res.Success, "", res.Message);
            // setShow(false);
          }
        });
    }
  };
  const handleCallback = (childData) => {
    const data64 = [];
    setLoading(true);
    let type = childData.url.split(",")[0];
    if (type.includes("application/pdf")) {
      data64.push(childData.url);
      setLoading(false);
    } else {
      const img = new Image();
      img.src = childData.url;
      img.onload = () => {
        const pdf = new jsPDF();

        const pageWidth = pdf.internal.pageSize.getWidth();
        const ratio = img.width / img.height;
        if (img.width > img.height) {
          img.width = 185;
          img.height = img.width / ratio;
        } else {
          img.height = 280;
          img.width = img.height * ratio;
        }
        const pageHeight = pdf.internal.pageSize.getHeight();
        var centerX = null;
        var centerY = null;

        const scaledWidth = Math.min(img.width, pageWidth);

        // Calculate the scaling factor for height based on the adjusted width
        const scaleFactor = scaledWidth / img.width;

        // Calculate the scaled height
        const scaledHeight = img.height * scaleFactor;

        // Calculate the center position for the scaled image
        centerX = (pageWidth - scaledWidth) / 2;
        centerY = (pdf.internal.pageSize.getHeight() - scaledHeight) / 2;
        pdf.addImage(
          img,
          "JPEG",
          centerX,
          centerY,
          img.width,
          img.height,
          "",
          "SLOW"
        );
        var base = pdf.output("datauristring");
        data64.push(base);
        setLoading(false);
      };
    }
    setImageData(data64);
  };
  const fetchDocuments = useCallback(() => {
    setLoading(true);
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    try {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "/api/cardocumentmaster/Allcardocument?carid=" +
        Catloguedetail.carid,
        header
      )
        .then((response) => response.json())
        .then((res) => {
          setCarDOclist(res.Data);
          setLoading(false);
        });
    } catch (error) {
      console.log("Error :", error);
      setLoading(false);
    }
  }, [Catloguedetail.carid]);
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const Docdata = await fetchDataFromApi(
          "/api/cardocumentmaster/alldocument"
        );

        setDOclist(Docdata.Data);
        setDoctype(Docdata.Data[0].id);
        if (Catloguedetail.carid) {
          fetchDocuments();
        }

      } catch (error) {
        console.log("Exception Found:", error);
        setLoading(false);
      }
    };

    fetchdata();
  }, [open, Catloguedetail.carid, fetchDocuments]);


  return (
    <div>
      <Modal
        show={open}
        onHide={(e) => handlemodal()}
        id="resultpopup"
        className="modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">Add Documents</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            {loading ? <Loader /> : null}
            <label className="form-label">Document type</label>
            <select className="form-select" name="Doctype" onChange={Onchange}>
              {DOclist.map((list) => (
                <option value={list.id} key={list.id}>
                  {list.DocumentTitle}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Document Name</label>
            <input
              type="text"
              className="form-control"
              onChange={handleImageChange}
              name="filename"
            />

            <div className="text-danger">{error.name}</div>
          </div>
          <div className="mb-3">
            <CatimgUpload parentCallback={(data) => handleCallback(data)} />
            <div className="text-danger">{error.doc}</div>
          </div>
          <div className="text-center">
            <input
              type="submit"
              value="Submit"
              className="attachbtn"
              onClick={handleGeneratePDF}
            />
          </div>

          <Document
          dataFromParent={{ no: Catloguedetail.carnumber, list: CarDOclist }}
        />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddDoc;