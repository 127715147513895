import logo from "./logo.svg";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import NoInternetConnection from "./offline";
import Login from "./Component/Account/Login";
import Register from "./Component/Account/Register";
import Dashboard from "./Component/Dashboard";
import CarInquiry from "./Component/carInquiry";
import Payment from "./Component/Payment";
import MyCar from "./Component/MyCar";
import CarDetail from "./Component/CarDetail";
import Catlogue from "./Component/Catalogue/Innercatalogue/MyCatlogue";
import Catlogue_Detail from "./Component/Catalogue/Innercatalogue/Catlogue_Detail";
import Catlogueshare from "./Component/Catalogue/Outercatalogue/Catlogueshare";
import Editinquiry from "./Component/Editinquiry";
import Soldunsold from "./Component/Soldunsold";
import Service from "./Component/Services/Service";
import CatDetails from "./Component/Catalogue/Outercatalogue/CatDetails";
import CreateStatus from "./Component/CreateStatus";
import Statuspage from "./Component/Statuspage";
import GenerateQr from "./Component/GenerateQr";
import SearchDoc from "./Component/SearchDoc";
import Leadform from "./Component/Lead-Section/Leadform";
import Leaddata from "./Component/Lead-Section/Leaddata";
// import { ApiProvider } from "./Component/ApiContext";
import Profile from "./Component/Account/Profile";
import Carstationary from "./Component/Services/Carstationary";
import Updatedcarprice from "./Component/Services/Updatedcarprice";
import CheckRC from "./Component/Services/CheckRc";
import Addlead from "./Component/Lead-Section/Addlead";
import Supportpage from "./Component/Supportpage";
import Changepassword from "./Component/Account/Changepassword";
import ForgetPassword from "./Component/Account/ForgetPassword";
import NewPassword from "./Component/Account/NewPassword";
import CarValuation from "./Component/CarValuation-calc";
import Help from "./Component/Help";
import IndividualCarPhotoShare from "./Component/Individual_CarPhotoShare";
import { LoanEmi } from "./Component/Services/Clac-Loanemi";
import SearchmoreCars from "./Component/SearchmoreCars";
import { SearchProvider } from "./Component/Searchcontext";
import OtherCarDetails from "./Component/OtherCarDetials";
import Challan from "./Component/Services/Challan";
import AllFrame from "./Component/Greeting_Module/AllFrame";
import Specifiedcarcatlogue from "./Component/Catalogue/Innercatalogue/Specifiedcarcatlogue";
import Specficatlogueshare from "./Component/Catalogue/Outercatalogue/Specficcatlogueshare";
import Undermaintenance  from "./Component/Undermaintenance";
import AllImages from "./Component/Greeting_Module/AllImages";
import MessageTemplate from "./Component/Services/Message_Template";
import ObjectDetection from "./Component/tryimagedetection";
import Challaninuse from "./Component/Services/Challaninuse";
import Report from "./Component/Reports/Report";
import Stockrecord from "./Component/Stockrecord";
import SearchBuyerSeller from "./Component/SerchBuyerSeller";
import AllUpdatedNotification from "./Component/AllUpdatedNotification";
import SalesExecutive from "./Component/Lead-Section/SalesExecutive";
import Trashbin from "./Component/Trashbin";
function App() {
  return (
    // <ApiProvider>
    
    <div>
      <NoInternetConnection>
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route exact path="/Login" element={<Login />} />
            <Route exact path="/Register" element={<Register />} />
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/CarInquiry" element={<CarInquiry />} />
            <Route exact path="/Payment" element={<Payment />} />
            <Route exact path="/MyCar" element={<MyCar />} />
            <Route exact path="/CarDetail" element={<CarDetail />} />
            <Route exact path="/Catlogue/:id" element={<Catlogue />} />
            <Route exact path="/Catlogueshare/:id/:name" element={<Catlogueshare />}/>
            <Route exact path="/Catlogue_Detail/:carno" element={<SearchProvider>
              <Catlogue_Detail /></SearchProvider>} />
            <Route exact path="/Editinquiry" element={<Editinquiry />} />
            <Route exact path="/Soldunsold" element={<Soldunsold />} />
            <Route exact path="/Service" element={<Service />} />
            <Route exact path="/CatDetails/:carno/:dealerid/:brandoption" element={<CatDetails />}/>
            <Route exact path="/CreateStatus" element={<CreateStatus />} />
            <Route exact path="/Statuspage" element={<Statuspage />} />
            <Route exact path="/GenerateQr" element={<GenerateQr />} />
            <Route exact path="/SearchDoc" element={<SearchDoc />} />
            <Route exact path="/Leadform/:no/:Rand/:dealerid" element={<Leadform />}/>
            <Route exact path="/Leaddata" element={<Leaddata />} />
            <Route exact path="/Profile" element={<Profile />} />
            <Route exact path="/Carstationary" element={<Carstationary />} />
            <Route exact path="/Updatedcarprice" element={<Updatedcarprice />}/>
            <Route exact path="/CheckRC" element={<CheckRC />} />
            <Route exact path="/Addlead" element={<Addlead />} />
            <Route exact path="/Supportpage" element={<Supportpage />} />
            <Route exact path="/Changepassword" element={<Changepassword />} />
            <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
            <Route exact path="/NewPassword" element={<NewPassword />} />
            <Route exact path="/CarValuation" element={<CarValuation />} />
            <Route exact path="/Challan" element={<Challan />} />
            <Route exact path="/Undermaintenance" element={<Undermaintenance />} />
            <Route exact path="/Help" element={<Help />} />
            <Route exact path="/AllImages" element={<AllImages />} />
            <Route exact path="/IndividualCarPhotoShare/:caRand/:dealerid" element={<IndividualCarPhotoShare />}/>
            <Route exact path="/LoanEmi" element={<LoanEmi />} />
            <Route exact path="/SearchmoreCars" element={<SearchProvider><SearchmoreCars /></SearchProvider>}/>
            <Route exact path="/OtherCarDetails/:carno/" element={<SearchProvider><OtherCarDetails /></SearchProvider>}/>
            <Route exact path="/AllFrame" element={<AllFrame />} />
            <Route exact path="/Specifiedcarcatlogue/" element={<SearchProvider><Specifiedcarcatlogue /></SearchProvider>}/>
            <Route exact path="/Specficatlogueshare/:id/:modelid/:Brand" element={<Specficatlogueshare />} />
            <Route exact path="/MessageTemplate" element={<MessageTemplate />} />
            <Route exact path="/ObjectDetection" element={<ObjectDetection />} />
            <Route exact path="/Challaninuse" element={<Challaninuse />} />
            <Route exact path="/Report" element={<Report />} />
            <Route exact path="/Stockrecord/:name" element={<Stockrecord />} />
            <Route exact path="/SearchBuyerSeller" element={<SearchBuyerSeller />} />
            <Route exact path="/AllUpdatedNotification" element={<AllUpdatedNotification />} />
            <Route exact path="/SalesExecutive" element={<SalesExecutive />} />
            <Route exact path="/Trashbin" element={<Trashbin />} />
          </Routes>

        </Router>
      </NoInternetConnection>
    </div>
    // {/* </ApiProvider> */}
  );
}

export default App;
