import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { OnLogout, Userlogin } from "./Utils/general-function";
import useApiData from "./useApiData";

const Header = React.memo((props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  // const data=useApiData();
  // console.log(data,"Header");
  const navigate = useNavigate();
  const menuRef = useRef(null);

  //  for not showing menubar in user with not upodeted profiile
  let nCity = "data";

  if (props.updated === "") {
    nCity = props.updated;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        const btn = document.querySelector(".sharbtnpos");
        if (btn) {
          btn.style.display = "inline-flex";
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
    const btn = document.querySelector(".sharbtnpos");
    if (btn) {
      btn.style.display = "none";
    }
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    const btn = document.querySelector(".sharbtnpos");
    if (btn) {
      btn.style.display = "inline-flex";
    }
  };

  return (
    <div className="fixheader">
      <header className="d-flex justify-content-between align-items-center">
        <div className="test d-flex flex-row align-items-center">
          {nCity && (
            <div className="menubtn" onClick={handleToggleMenu} ref={menuRef}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}

          <a href="/" className="logodiv">
            <img src="assets/images/logo.png" alt="" />
          </a>

        </div>
        <Link to="/Supportpage" style={{ marginRight: "15px" }}>
          <img src="assets/images/support.png" alt="" style={{ maxWidth: "50px" }} />
        </Link>
      </header>
      <div id="overlay" style={{ right: menuOpen ? "0" : "100%" }}></div>

      <div className={`menubar ${menuOpen ? "open" : ""}`}>
        <span
          id="closemenu"
          onClick={handleMenuClose}
          style={{ top: menuOpen ? "0" : "-30px" }}
        >
          <span className="material-symbols-outlined">close</span>
        </span>
        <div className="user-info">
          <a href="#/Profile" className="d-flex flex-row align-items-center">
            <span>Hello,{localStorage.getItem("Ca1nioamed")}</span>
          </a>
        </div>
        <ul id="vertical-menu">
          <li onClick={handleMenuClose}>
            <Link to="/">
              <img src="assets/images/dashboard12.png" alt="" />
              <div>
                <span>Dashboard</span>
                <p>Centralized cars hub</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/CarInquiry">
              <img src="assets/images/add-car12.png" alt="" />
              <div>
                <span>Add vehicle</span>
                <p>Listing your vehicle</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/MyCar">
              <img src="assets/images/car12.png" alt="" />
              <div>
                <span>My vehicles</span>
                <p>My vehicle portfolio</p>
              </div>
            </Link>
          </li>

          <li onClick={handleMenuClose}>
            <Link to={`/Catlogue/${localStorage.getItem("Ca1itd")}`}>
              <img src="assets/images/catalogue12.png" alt="" />
              <div>
                <span>My catalogue</span>
                <p>Explore your portfolio</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/CreateStatus">
              <img src="assets/images/status12.png" alt="" />
              <div>
                <span>Create status</span>
                <p>share your vehicle status</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/GenerateQr">
              <img src="assets/images/qr-code12.png" alt="" />
              <div>
                <span>Vehicle QRs</span>
                <p>get vehicle details by scan</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/SearchDoc">
              <img src="assets/images/audit1.png" alt="" />
              <div>
                <span>Vehicle documents</span>
                <p>Find vehicle documents</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/SalesExecutive">
              <img src="assets/images/sales-marketing.png" alt="" />
              <div>
                <span>Add Sales Executive</span>
                <p>List your sales executives</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Leaddata">
              <img src="assets/images/list1.png" alt="" />
              <div>
                <span>Leads</span>
                <p>Get your leadlist</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Addlead">
              <img src="assets/images/add-user12.png" alt="" />
              <div>
                <span>Add lead</span>
                <p>Add your lead</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Stockrecord/Add">
              <img src="assets/images/addbuy.png" alt="" />
              <div>
                <span>Buyer/Seller entry</span>
                <p>Add new buyer or seller entry </p>
              </div>
            </Link>
          </li>
          {/* <li onClick={handleMenuClose}>
            <Link to="/SearchBuyerSeller">
              <img src="assets/images/note.png" alt="" />
              <div>
                <span>Buyer/Seller lookup</span>
                <p>Update  buyer or seller record</p>
              </div>
            </Link>
          </li> */}
          <li onClick={handleMenuClose}>
            <Link to="/Report">
              <img src="assets/images/report.png" alt="" />
              <div>
                <span>Reports</span>
                <p>View portfolio analytics</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Service">
              <img src="assets/images/service12.png" alt="" />
              <div>
                <span>Services</span>
                <p>Get vehicle services</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/AllImages">
              <img src="assets/images/all-images.png" alt="" />
              <div>
                <span>Marketing post</span>
                <p>Get your social-media post</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Trashbin">
              <img src="assets/images/trash.png" alt="" />
              <div>
                <span>Trash Bin</span>
                <p>Get your deleted cars here</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Profile">
              <img src="assets/images/profile12.png" alt="" />
              <div>
                <span>My Profile</span>
                <p>Update Your profile</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Changepassword">
              <img src="assets/images/security12.png" alt="" />
              <div>
                <span>Change password</span>
                <p>Change your password</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to={null} onClick={(e) => OnLogout()}>
              <img src="assets/images/logout1.png" alt="" />
              <div>
                <span>Logout</span>
                <p>Sign out your app</p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default Header;

// const handleToggleMenu = () => {
//   setIsMenuOpen(!isMenuOpen);
//   const overlay = document.querySelector("#overlay");
//   const closemenu = document.querySelector("#closemenu");

//   if (isMenuOpen) {
//     overlay.style.right = "0";
//     closemenu.style.top = "0";
//   }
// };
// document.querySelector('meta[property="og:title"]').setAttribute("content", "Carrrr");

// const handleCloseMenu = () => {
//   setIsMenuOpen(false);
// };
