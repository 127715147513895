import React, { useEffect, useState, useCallback } from "react";
import Header from "./Header";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  Toast,
  urltobase64,
  fetchDataFromApi,
  Userlogin,
  posttocarthipage,
} from "./Utils/general-function";
import Loader from "./Utils/Loader";
import CatimgUpload from "./Uploadimage/Cardoc_Upload";
import Info from "./Utils/Info";
import Titleinfo from "./Utils/Titleinfo";
import "react-confirm-alert/src/react-confirm-alert.css";
import Document from "./Document";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function CarDetail(props) {
  const location = useLocation();
  const details = location.state.details;
  const [show, setShow] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [DOclist, setDOclist] = useState([]); //for getting all multiple types of doc
  const [Doctype, setDoctype] = useState(); // for storing selected doc type
  const [loading, setLoading] = useState(true);
  const [CarDOclist, setCarDOclist] = useState([]);// storing all uploded docs.
  const [CatStatus, setCatStatus] = useState(details.Cateloge);
  const [CatPhoto, setCatPhoto] = useState(details.Cateloge);
  const [Docname, setDocname] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();

  function handlemodal() {
    setShow(!show);
  }
  const handleImageChange = (e) => {
    setDocname(e.target.value);
  };
  const validate = () => {
    let errors = {};
    let isValid = true;

    if (imageData.length === 0) {
      isValid = false;
      errors["doc"] = "Please upload document";
    }
    // if (Docname === "") {
    //   isValid = false;
    //   errors["name"] = "Kindly name the document for saving.";
    // }

    setError(errors);

    return isValid;
  };
  const handleGeneratePDF = () => {
    if (validate()) {
      setError({});
      setLoading(true);
      const param = {
        carid: details.carid,
        Carno: details.carnumber,
        documentid: Doctype,
        documentlist: imageData[0],
        documentname: Docname,
      };
      var formBody = [];
      for (var property in param) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(param[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "/api/cardocumentmaster/Addcardocument",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.Success) {
            setImageData([]);
            setDoctype(DOclist[0].id);
            fetchDocuments();
            setShow(false);
            setLoading(false);
          } else {
            setLoading(false);
            Toast(res.Success, "", res.Message);
            setShow(false);
          }
        });
    }
  };

  const Onchange = (e) => {
    setDoctype(e.target.value);
  };

  async function Onshare() {
    // document.getElementById("carno").innerText =
    //   details.carnumber.substring(0, 4) + "**" + details.carnumber.substring(6);
    setLoading(true);
    const canvasElement = document.getElementById("forshare");
    let date = new Date();
    let ms = date.getMilliseconds();
    const name =
      details.modelyear +
      details.companyid +
      details.carmodelid +
      details.variantid +
      "_" +
      ms;
    const scale = 3;
    const canvas = await html2canvas(canvasElement, { scale });
    const imgData = await canvas.toDataURL();
    const blob = await (await fetch(imgData)).blob();
    const filesArray = [
      new File([blob], name + ".png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      }),
    ];
    setLoading(false);

    // document.getElementById("carno").innerText = details.carnumber;
    if (navigator.canShare && navigator.canShare({ files: filesArray })) {
      navigator
        .share({
          files: filesArray,
        })

        .then(() => console.log("Share was successful."))

        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  const fetchDocuments = useCallback(() => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    try {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "/api/cardocumentmaster/Allcardocument?carid=" +
        details.carid,
        header
      )
        .then((response) => response.json())
        .then((res) => {
          setCarDOclist(res.Data);
          setLoading(false);
        });
    } catch (error) {
      console.log("Error :", error);
      setLoading(false);
    }
  }, [details.carid]);

  const handleCallback = (childData) => {
    const data64 = [];
    setLoading(true);
    let type = childData.url.split(",")[0];
    if (type.includes("application/pdf")) {
      data64.push(childData.url);
      setLoading(false);
    } else {
      const img = new Image();
      img.src = childData.url;
      img.onload = () => {
        const pdf = new jsPDF();

        const pageWidth = pdf.internal.pageSize.getWidth();
        const ratio = img.width / img.height;
        if (img.width > img.height) {
          img.width = 185;
          img.height = img.width / ratio;
        } else {
          img.height = 280;
          img.width = img.height * ratio;
        }
        const pageHeight = pdf.internal.pageSize.getHeight();
        var centerX = null;
        var centerY = null;

        const scaledWidth = Math.min(img.width, pageWidth);

        // Calculate the scaling factor for height based on the adjusted width
        const scaleFactor = scaledWidth / img.width;

        // Calculate the scaled height
        const scaledHeight = img.height * scaleFactor;

        // Calculate the center position for the scaled image
        centerX = (pageWidth - scaledWidth) / 2;
        centerY = (pdf.internal.pageSize.getHeight() - scaledHeight) / 2;
        pdf.addImage(
          img,
          "JPEG",
          centerX,
          centerY,
          img.width,
          img.height,
          "",
          "SLOW"
        );
        var base = pdf.output("datauristring");
        data64.push(base);
        setLoading(false);
      };
    }
    setImageData(data64);
  };
  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        const Docdata = await fetchDataFromApi(
          "/api/cardocumentmaster/alldocument"
        );
        if (
          Docdata.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setDOclist(Docdata.Data);
        setDoctype(Docdata.Data[0].id);
        setLoading(false);
        fetchDocuments();
        urltobase64(details.carphoto).then((res) => {
          setCatPhoto(res);
        });
      } catch (error) {
        console.log("Exception Found:", error);
        setLoading(false);
      }
    };

    fetchdata();
  }, [details.carphoto, fetchDocuments, navigate]);

  const onDocument = () => {
    setShow(true);
  };

  const Addtocatlogue = (status) => {
    if (details?.CarStatus === "Sold") {
      toast.info("Sold vehicle can't be added to catalogue");
      return;
    }
    let Request;
    let Message;
    let updatecat;
    if (status === "No") {
      Message = "Vehicle Successfully added in Cateloge.";
      Request = "add";
      updatecat = "Yes";
      Addremovecatlogue(
        details?.carnumber,
        details?.carid,
        Request,
        Message,
        updatecat
      );
      if (localStorage.getItem("Permission") === "Allow") {
        //posttocarthipage(details);
      }
    } else {
      Message = "Vehicle Successfully removed from Cateloge.";
      Request = "remove";
      updatecat = "No";

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="popup-overlay px-3 py-3 text-center">
              <p>Are you sure you want to remove this vehicle from catlogue?</p>
              <p style={{ fontSize: "14px", color: "red", textAlign: "left" }}>
                Note: Removing the vehicle from the catalogue will delete all
                its uploaded photos.
              </p>
              <button
                className="submitbtn me-2 popup-btn"
                onClick={() => {
                  Addremovecatlogue(
                    details?.carnumber,
                    details?.carid,
                    Request,
                    Message,
                    updatecat
                  );
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                className="submitbtn me-2 popup-btn"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>
            </div>
          );
        },
      });
    }
  };

  const Addremovecatlogue = (carnumber, carid, Request, Message, updatecat) => {
    setLoading(true);
    const header = {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
      "/api/Carmaster/addUpdatecateloge?carnumber=" +
      carnumber +
      "&carid=" +
      carid +
      "&carstatus=" +
      Request,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        Toast(res.Success, Message, "Something went wrong");
        if (res.Success === true) {
          setLoading(false);
          setCatStatus(updatecat);
        }
      });
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="text-end pt-3 pe-2">
        <button onClick={(e) => Onshare()} className="sharebtn sharbtnpos">
          Share
          <span>
            <img src="assets/images/share.png" alt="" />
          </span>
        </button>
      </div>
      <div className="page-space" style={{ marginTop: "-70px" }}>
        <div id="forshare" style={{ paddingTop: "60px" }}>
          <Titleinfo
            dataFromParent={{ data: details, carno: details?.carnumber }}
          />

          <div className="position-relative">
            <img src={CatPhoto} className="car-imagess" alt="" />
          </div>

          <Info dataFromParent={details} />
        </div>

        <div className="car-grid2">
          <div>
            <button className="car-btns red" onClick={(e) => onDocument()}>
              Add Documents
            </button>
          </div>
          <div>
            <button
              className="car-btns black"
              onClick={(e) => Addtocatlogue(CatStatus)}
            >
              {CatStatus === "No" ? "Add To Catalogue" : "Remove From Catlogue"}
            </button>
          </div>
        </div>
        <Document
          dataFromParent={{ no: details.carnumber, list: CarDOclist }}
        />
      </div>


      {/* <AddDoc Catloguedetail={details} open={show} handlemodal={handlemodal} /> */}
      <Modal
        show={show}
        onHide={(e) => handlemodal()}
        id="resultpopup"
        className="modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">Add Documents</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Document type</label>
            <select className="form-select" name="Doctype" onChange={Onchange}>
              {DOclist.map((list) => (
                <option value={list.id} key={list.id}>
                  {list.DocumentTitle}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Document Name</label>
            <input
              type="text"
              className="form-control"
              onChange={handleImageChange}
              name="filename"
            />

            <div className="text-danger">{error.name}</div>
          </div>
          <div className="mb-3">
            <CatimgUpload parentCallback={(data) => handleCallback(data)} />
            <div className="text-danger">{error.doc}</div>
          </div>
          <div className="text-center">
            <input
              type="submit"
              value="Submit"
              className="attachbtn"
              onClick={handleGeneratePDF}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CarDetail;

// const pdfWidth = pdf.internal.pageSize.getWidth();
// const pdfHeight = pdf.internal.pageSize.getHeight();
// const imgWidth = img.width;
// const imgHeight = img.height;
// console.log("imgWidth:", imgWidth, imgHeight);

// let widthRatio = (pdfWidth - 20) / imgWidth; // Subtracting margins
// let heightRatio = (pdfHeight - 20) / imgHeight; // Subtracting margins
// console.log("widthRatio:", widthRatio, heightRatio);

// const ratio = Math.min(widthRatio, heightRatio);
// console.log("ratio:", ratio);

// const scaledWidth = imgWidth * ratio;
// const scaledHeight = imgHeight * ratio;
// console.log("scaledWidth:", scaledWidth, scaledHeight);

// const x = (pdfWidth - scaledWidth) / 2;
// const y = (pdfHeight - scaledHeight) / 2;
// console.log("x:", x, y);

// pdf.addImage(img, "JPEG", x, y, scaledWidth, scaledHeight,"","SLOW");
