import React, { useState, useEffect } from 'react';
import Loader from "./Utils/Loader";
import Resizer from "react-image-file-resizer";
import { confirmAlert } from "react-confirm-alert";
import { Modal } from "react-bootstrap";
import { Log, log } from '@tensorflow/tfjs';

function AddMorePhotos({ Catloguedetail, open, handlemodal }) {

    const [loading, setLoading] = useState(true);
    const [maxerror, setMaxerror] = useState("");
    const [Photolist, setPhotolist] = useState([]);
    // const [show, setShow] = useState(false);



    useEffect(() => {
        setPhotolist([]);
        // Fetch photos only when modal is opened
        getphotos();
        setMaxerror("")

    }, [open, Catloguedetail.Rand]);

    const getphotos = () => {
        fetch(
            process.env.REACT_APP_API_ENDPOINT +
            "/api/CarCateloge/catelogecardetailsbyrandomno?Randno=" +
            Catloguedetail.Rand + "&dealerid=" + localStorage.getItem("Ca1itd")
        )
            .then((response) => response.json())
            .then((res) => {

                if (res.Success) {
                    setPhotolist(res.Data.catlogephotolist);
                }
                setLoading(false)
            });
    }

    // function handlemodal() {
    //     setShow(!show);

    // }
    const handleCallback = (childData) => {
        setLoading(true);

        const param = {
            carid: Catloguedetail.carid,
            carnumber: Catloguedetail.carnumber,
            imageslist: JSON.stringify(childData),
        };
        var formBody = [];
        for (var property in param) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(param[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
            },
            body: formBody,
        };
        fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/CarCateloge/addcarcateloge",
            requestOptions
        )
            .then((response) => response.json())
            .then((res) => {
                console.log(res);
                setLoading(false);
                if (res.Success) {
                    getphotos();
                }
                else {
                    setMaxerror(res.Message)
                }
            });
    };
    const handleFileChange = (e) => {
        setLoading(true);
        const files = Array.from(e.target.files); // Convert FileList to array
        if (files.length + Photolist.length > 20) {
            20 - Photolist.length === 0 ? setMaxerror("20-photo limit; unable to upload more.") :
                setMaxerror(`You can upload up to ${20 - Photolist.length} photos.The max limit is 20`);
            return
        }
        setMaxerror("")
        Promise.all(
            files.map((file) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const img = new Image();
                        img.onload = () => {
                            const aspectRatio = img.width / img.height;
                            const fixedWidth = 700;
                            const fixedHeight = fixedWidth / aspectRatio;

                            Resizer.imageFileResizer(
                                file,
                                fixedWidth,
                                fixedHeight,
                                "JPEG",
                                75,
                                0,
                                (resizedFile) => {
                                    resolve({ url: resizedFile, name: file.name });
                                },
                                "base64",
                                fixedWidth,
                                fixedHeight
                            );
                        };
                        img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                });
            })
        ).then((resizedImages) => {
            handleCallback(resizedImages);
        });
    };
    const opendeletepopup = (random) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="popup-overlay px-3 py-3 text-center">
                        <p>Are you sure you want to delete this photo?</p>
                        <button
                            className="submitbtn me-2 popup-btn"
                            onClick={() => {
                                OnDelete(random);
                                onClose();
                            }}
                        >
                            Yes
                        </button>
                        <button
                            className="submitbtn me-2 popup-btn"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            No
                        </button>
                    </div>
                );
            },
        });
    };
    const OnDelete = (random) => {
        const header = {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
            },
        };
        fetch(
            process.env.REACT_APP_API_ENDPOINT +
            "/api/CarCateloge/DeleteCarCateloge?Rand=" +
            random,
            header
        )
            .then((response) => response.json())
            .then((res) => {
                let updatelist = Photolist.filter((d) => d.Rand !== random);
                setPhotolist(updatelist);
            });
    };
    return (
        <div>

            <Modal
                show={open}
                onHide={(e) => handlemodal()}
                id="resultpopup"
                className="modal"
            >
                <Modal.Header closeButton>
                    <h4 className="modal-title">Add multiple photos</h4>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loader /> : null}
                    {Catloguedetail.Cateloge === "Yes" ?
                        <div className="text-center py-3">
                            {/* <label htmlFor="image"> */}
                            <input
                                type="file"
                                name="image"
                                id="image"
                                accept=".jpg,.jpeg,.png"
                                className="form-control d-none"
                                onChange={handleFileChange}
                                multiple
                            />
                            <label className="catlg-addimg" htmlFor="image">
                                <span className="mticon">
                                    <span className="material-symbols-outlined">add_a_photo</span>
                                </span>{" "}
                                Add More Images
                            </label>
                            <div className="text-danger">{maxerror}</div>

                            {/* <CatimgUpload parentCallback={(data) => handleCallback(data)} /> */}
                        </div> :
                        <label className="text-center p-3 text-danger">To upload multiple photos, you must first add the car to the catalog.</label>
                    }
                    <div className="carimglist carimgpopuplist">

                        {Photolist.map((list, i) => (

                            <div className="listimg" key={list.carphoto}>
                                <div className="all-listimg">
                                    <img src={list.carphoto} alt="" />
                                    {i === 0 ? null : (
                                        <button onClick={(e) => opendeletepopup(list.Rand)}>
                                            <img
                                                src="assets/images/car-delete.png"
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default AddMorePhotos;