import React, { useEffect, useState } from "react";
import Loader from "../Utils/Loader";
import Header from "../Header";
import axios from "axios";
import { Userlogin, PasswordPopup, Toast } from "../Utils/general-function";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

function SalesExecutive(props) {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({ name: "", status: "Active", Mobileno: "" });
  const [errors, setErrors] = useState({});
  const [list, setlist] = useState([]);
  const [editid, seteditid] = useState("");
  const [show, setshow] = useState(false);
  const navigate = useNavigate();

  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    }
  }
  const handleChange = (e) => {
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: value,
    }));
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "This feild is required";
    }
    // if (typeof input["name"] !== "undefined") {
    //   var re = new RegExp(/^[a-zA-Z ]*$/)
    //   if (!re.test(input["name"])) {
    //     isValid = false;
    //     errors["name"] = "Please enter only characters.";
    //   }
    // }
    if (!input["Mobileno"]) {
      isValid = false;
      errors["Mobileno"] = "This feild is required";
    }
    if (typeof input["Mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["Mobileno"])) {
        isValid = false;
        errors["Mobileno"] = "Please enter only number.";
      } else if (input["Mobileno"].length !== 10) {
        isValid = false;
        errors["Mobileno"] = "Enter valid mobile number.";
      }
    }
    setErrors(errors);

    return isValid;
  };

  const handleSubmit = (data) => {
    if (Validate()) {
      setLoading(true);
      let Point;
      if (data === "Add") {
        Point = "AddSalesExecutive"
      } else {
        Point = "EditSalesExecutive"
      }

      var param = {
        Name: input.name,
        Mobileno: input.Mobileno,
        status: input.status,
        Rand: data
      };
      var formBody = new URLSearchParams(param).toString();

      const requestOptions = {
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
      };

      axios.post(process.env.REACT_APP_API_ENDPOINT + "/api/SalesExecutive/" + Point,
        formBody, requestOptions
      )
        .then((res) => {
          setLoading(false);
          if (res.data.Success) {
            if (data === "Add") {
              const obj = [{
                Name: input.name,
                Mobileno: input.Mobileno,
                status: input.status,
                Rand: res.data.Data
              }]

              setlist([...list, ...obj])

            } else {
              const newData = list.map((d) =>
                d.Rand === data
                  ? { ...d, ...param }
                  : d
              );
              setlist(newData);
            }
            setInput({
              name: "",
              Mobileno: "",
              status: "Active"
            })
            setshow(false);
          } else {
            Toast(res.data.Success, "", res.data.Message);
          }
        })
        .catch((error) => console.error(error));
    }
  };
  
  const AddEditForm = (action) => {
    return (
      <>
        <div className="text-center mb-2">
          <h2 className="page-title">{action === "Add" ? "Add" : "Edit"} Sales Executive</h2>
        </div>
        <div className="form-div cstmpading">
          <input
            type="text"
            placeholder="Name"
            className="form-control"
            name="name"
            value={input.name}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.name}</span>
        </div>
        <div className="d-flex">
          <div className="form-div cstmpading flex-fill me-2">
            <input
              type="text"
              placeholder="Mobile No."
              className="form-control"
              name="Mobileno"
              value={input.Mobileno}
              onChange={handleChange}
            />
            <span className="text-danger">{errors.Mobileno}</span>
          </div>
          <div className="form-div cstmpading">
            <select
              className="form-select form-control"
              name="status"
              value={input.status}
              onChange={handleChange}
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={() => handleSubmit(action)}>
            Submit
          </button>
        </div>
      </>
    )
  }

  const onEdit = (random) => {
    setLoading(true);
    seteditid(random);
    setErrors({})
    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/SalesExecutive/EditSalesExecutive?Rand=" + random, header)
      .then((res) => {
        const resp = res.data
        setLoading(false)
        if (resp.Success) {
          setInput({
            name: resp.Data.Name,
            Mobileno: resp.Data.Mobileno,
            status: resp.Data.status
          })
        }
        else {
          Toast(resp.Success, "", resp.Message)
        }

        setshow(true)
      })
      .catch((error) => console.log(error))

  }

  const onDelete = (random) => {
    setLoading(true);
    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/SalesExecutive/Delete?Rand=" + random, header)
      .then((res) => {
        const resp = res.data
        if (resp.Success) {
          setlist(list.filter((d) => d.Rand !== random))
        }
        else {
          Toast(resp.Success, "", resp.Message)
        }
        setLoading(false)
      })
      .catch((error) => console.log(error))
  }

  const handleclose = () => {
    setshow(false)
    setInput({
      name: "",
      Mobileno: "",
      status: "Active"
    })
    setErrors({})

  }
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/login");
      return
    }

    axios.get(process.env.REACT_APP_API_ENDPOINT + "/api/SalesExecutive/AllSalesExecutiveDetails", header)
      .then((res) => {
        setlist(res.data.Data || []);
        setLoading(false)
      })
      .catch((error) => console.log(error)
      )

    setLoading(true);

  }, [])
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="content">
        {AddEditForm("Add")}
        {/*------------------------- SalesExecutive list---------------------- */}
        <div className="mt-4">
          {list.map((data) => (
            <div className="car-docsdiv" key={data.Rand}>
              <div className="d-flex align-items-center flex-nowrap">
                <img src="assets/images/profile-svg.svg" alt="" style={{ width: "22px" }} />
                <div className="flex-grow-1 pe-3" style={{ marginLeft: "0.4rem" }}>
                  <label>{data.Name}</label>
                </div>
                <div className="d-inline-flex flex-nowrap">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22px"
                    height="100%"
                    viewBox="0 0 24 24"
                    className="me-1"
                    onClick={() => {
                      PasswordPopup({
                        method: () => onEdit(data.Rand),
                        message: "To edit this record, we require your account password."
                      })
                    }}
                  >
                    <g
                      fill="none"
                      stroke="green"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    >
                      <path d="M9.533 11.15A1.823 1.823 0 0 0 9 12.438V15h2.578c.483 0 .947-.192 1.289-.534l7.6-7.604a1.822 1.822 0 0 0 0-2.577l-.751-.751a1.822 1.822 0 0 0-2.578 0z" />
                      <path d="M21 12c0 4.243 0 6.364-1.318 7.682C18.364 21 16.242 21 12 21c-4.243 0-6.364 0-7.682-1.318C3 18.364 3 16.242 3 12c0-4.243 0-6.364 1.318-7.682C5.636 3 7.758 3 12 3" />
                    </g>
                  </svg>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22px"
                    height="100%"
                    viewBox="2 2 21 26"
                    onClick={() => {
                      PasswordPopup({
                        method: () => onDelete(data.Rand),
                      })
                    }}
                  >
                    <path
                      fill="#ff1237"
                      d="M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6zM8 9h8v10H8zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"
                    />
                  </svg>

                </div>
              </div>
              <div>
                <div className="list-details">
                  <div className="ms-2">
                    <span style={{ fontSize: "12px", padding: "0 0 0 18px" }}>•{data.Mobileno} &nbsp;• {data.status}</span>
                  </div>
                </div>
              </div>

            </div>))}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleclose}
        id="resultpopup"
        className="modal"
      >
        <Modal.Header closeButton>
          {/* <h4 className="modal-title"></h4> */}
        </Modal.Header>
        <Modal.Body>
          {AddEditForm(editid)}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SalesExecutive;

