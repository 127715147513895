import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Loader from "./Utils/Loader";
import { Userlogin, PasswordPopup } from "./Utils/general-function";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import NoCars from "./Utils/NoCars";
import Defaultpreview from "./Utils/Defaultpreview";
function SearchBuyerSeller(props) {
  const [loading, setLoading] = useState(false);
  const [Originalist, setOriginalist] = useState([]);
  const [searchtext, setSearchText] = useState("");
  const [Carlist, setCarlist] = useState([]);
  const [Show, setShow] = useState(false);
  const navigate = useNavigate();
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };

  const Onchange = async (e) => {
    await setSearchText(e.target.value);
    let filterdlist = Originalist.filter((d) =>
      d.Carno.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setCarlist(filterdlist);
  };


  const onEdit = (rand) => {
    navigate("/Stockrecord/" + rand)
  }

  useEffect(() => {
    if (Userlogin()) {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
        "/api/BuyerSeller/AllBuyerSellerDetails",
        header
      )
        .then((response) => response.json())
        .then((res) => {

          setCarlist(res.Data || []);
          setOriginalist(res.Data || []);
          setLoading(false);
        });
    } else {
      navigate("/Login");
    }
  }, [navigate]);

  const formatResult = (Carlist) => {
    return (
      <>
        <ul className="doc-searchresult" id="searchlist">
          {Carlist.map((list) => (
            <li
              key={list.Rand}
              style={{ display: "block", textAlign: "left" }}
            >
              {list.Transaction}&nbsp;-&nbsp;{list.Carno}&nbsp;-&nbsp;{list.carmodelname}&nbsp;
              <img src="assets/images/edit-car.png" alt="" style={{ maxWidth: "26px", float: "right" }}
                onClick={() => {
                  PasswordPopup({
                    method: () => onEdit(list.Rand),
                    message: "To edit this record, we require your account password."
                  })
                }}
              />

            </li>
          ))}
        </ul>
      </>
    );
  };
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="page-space">
        <div className="car-searchbar">
          <button>
            <img src="assets/images/search-icon.png" alt="" />
          </button>
          <input
            type="text"
            className="doc-serachtext"
            placeholder="Enter vehicle no..."
            onChange={Onchange}
          />
        </div>
        {searchtext ? Carlist.length > 0 ? formatResult(Carlist) : <NoCars img="assets/images/no-record.png" msg="No record available" /> : <Defaultpreview img="assets/images/draft.png" msg="Update buyer or seller records with ease—just enter the car number !!" />}

      </div>
    </div>
  );
}

export default SearchBuyerSeller;
